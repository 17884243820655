<template>
  <div id="testingId">
    <b-row>
      <b-col
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
      >
        <b-button
          :disabled="['Licensing', 'Renew', 'Extend', 'PendingLicensing', 'PendingExtend', 'PendingRenew', 'RejectLicense', 'Revoke'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
          class="button-testing buttom-add"
          variant="outline-primary"
          @click="handleLicenseAccreditation"
        >
          Đề nghị cấp giấy chứng nhận kiểm định
        </b-button>
        <b-button
          :disabled="!['Licensing', 'Renew', 'Extend', 'Revoke'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
          class="button-testing buttom-add"
          variant="outline-primary"
          @click="handleRenewLicense"
        >
          Đề nghị cấp lại giấy chứng nhận kiểm định ATVSLĐ
        </b-button>
        <b-button
          :disabled="!['Licensing', 'Renew', 'Extend'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
          class="button-testing buttom-add"
          variant="outline-primary"
          @click="handleExtendLicense"
        >
          Đề nghị cấp giấy gia hạn chứng nhận kiểm định ATVSLĐ
        </b-button>
        <b-row>
          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="6"
          >
            <b-button
              :disabled="['NonLiscening'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
              variant="danger"
              class="buttom-add button-delete"
              @click="handleDeleteLicense"
            >
              Xóa
            </b-button>
          </b-col>
          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="6"
          >
            <b-dropdown
              id="dropdown-1"
              :disabled="['NonLiscening', 'RejectLicense', 'Revoke'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
              text="Tải đơn"
              variant="primary"
              class="buttom-add button-action"
              size="lg"
            >
              <b-dropdown-item
                v-if="![
                  'PendingRenew',
                  'PendingExtend',
                  'Licensing',
                  'Renew',
                  'Extend'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
                @click="openModalSeePdf(dataInfomationBusiness.id)"
              >
                <span class="align-middle">Đơn đề nghị cấp giấy chứng nhận đủ điều kiện hoạt động kiểm định</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="['Licensing', 'Renew', 'Extend'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
                @click="openModalSeePdfAccreditation(dataInfomationBusiness.id)"
              >
                <span class="align-middle">Giấy chứng nhận kiểm định</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="checkStatus"
                @click="openModalSeePdfEquipment(dataInfomationBusiness.id)"
              >
                <span class="align-middle">Danh mục thiết bị, dụng cụ phục vụ kiểm định</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="checkStatus"
                @click="openModalSeePdfDocument(dataInfomationBusiness.id)"
              >
                <span class="align-middle">Danh mục tài liệu phục vụ kiểm định</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="checkStatus"
                @click="openModalSeePdfInspector(dataInfomationBusiness.id)"
              >
                <span class="align-middle">Danh mục phân công kiểm định viên</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="['PendingExtend', 'PendingRenew'].includes(dataInfomationBusiness.statusLicenseAccrediation)"
                @click="openModalSeePdfRenewExtend(dataInfomationBusiness.id)"
              >
                <span class="align-middle">Đơn đề nghị gia hạn/ cấp lại giấy chứng nhận điều kiện hoạt động kiểm định</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
      >
        <b-row>
          <span class="title-text">Thông tin giấy phép</span>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Trạng thái</span>
          </b-col>
          <b-col>
            <b-badge
              pill
              :variant="colorStatus(dataInfomationBusiness.statusLicenseAccrediation)"
              class="border-status"
            >
              <span> {{ dataInfomationBusiness.statusLicenseAccrediationString }}</span>
            </b-badge>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="UserIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Số giấy phép</span>
          </b-col>
          <b-col>
            <span class="align-middle">{{ dataInfomationBusiness.numberLicense }}</span>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày cấp phếp</span>
          </b-col>
          <b-col>
            <span class="align-middle">{{ dataInfomationBusiness.dateLicense | formatDateToDDMM }}</span>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày hết hạn</span>
          </b-col>
          <b-col>
            <span class="align-middle">{{ dataInfomationBusiness.dateExpired | formatDateToDDMM }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- modal xem file pdf -->
    <modal-see-pdf
      :id="modalSeePdf"
      :pdfsrc="pdfsrc"
      @downloadFile="downloadFilePdf"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BButton, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import ModalSeePdf from './modal-create/ModalSeePdf.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BBadge,
    ModalSeePdf,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    dataInfomationBusiness: {
      type: Object,
      default: _ => {},
    },
    fetchDataBusiness: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalSeePdf: 'comfirm-pdf-business',
      pdfsrc: null,
      modeType: null,
    }
  },
  computed: {
    colorStatus() {
      const statusColor = {
        NonLiscening: 'secondary',
        PendingLicensing: 'warning',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        Extend: 'success',
        Renew: 'primary',
      }
      return status => statusColor[status]
    },

    checkStatus() {
      return ['PendingLicensing', 'PendingExtend', 'PendingRenew'].includes(this.dataInfomationBusiness.statusLicenseAccrediation)
    },
  },
  methods: {
    // Thêm đề nghị cấp phép kiểm định
    async handleLicenseAccreditation() {
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.ADD_SUSGESS_INSPECTION)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm đề nghị cấp phép kiểm định thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataBusiness()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm đề nghị cấp phép kiểm định không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // Thêm đề nghị gia hạn cấp phép kiểm định
    async handleExtendLicense() {
      const extendId = {
        licenseAccreditationId: this.dataInfomationBusiness.id,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.EXTEND_LICENSE, extendId)
      this.$hideAllPageLoading()
      if (res.status === 200) {
        this.$root.$bvToast.toast('Đề nghị gia hạn giấy kiểm định thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataBusiness()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Đề nghị gia hạn giấy kiểm định không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // Thêm đề nghị cấp phép kiểm định
    async handleRenewLicense() {
      const renewId = {
        licenseAccreditationId: this.dataInfomationBusiness.id,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.RENEW_LICENSE, renewId)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Đề nghị cấp lại giấy kiểm định thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataBusiness()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Đề nghị cấp lại giấy kiểm định không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // xóa cấp phép kiểm định
    handleDeleteLicense() {
      this.$emit('handleDeleteLicense')
    },

    // Mở modal Tạo đơn đề nghị cấp giấy chứng nhận đủ điều kiện hoạt động kiểm định
    openModalSeePdf(id) {
      this.modeType = 'require'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-require-license'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo danh mục thiết bị, dụng cụ phục vụ kiểm định
    openModalSeePdfEquipment(id) {
      this.modeType = 'equipment'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation-equipment'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo danh mục tài liệu phục vụ kiểm định
    openModalSeePdfDocument(id) {
      this.modeType = 'document'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation-document'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo danh mục phân công kiểm định viên
    openModalSeePdfInspector(id) {
      this.modeType = 'inspector'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation-inspector'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo giấy chứng nhận kiểm định
    openModalSeePdfAccreditation(id) {
      this.modeType = 'accreditation'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo đơn đề nghị gia hạn/ cấp lại giấy chứng nhận điều kiện hoạt động kiểm định
    openModalSeePdfRenewExtend(id) {
      this.modeType = 'renewExtend'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-require-renew-extend'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file pdf
    downloadFilePdf() {
      switch (this.modeType) {
        case 'require':
          this.nameFilePdf = 'DonDeNghiXacNhanDuDieuKienKiemDinh.pdf'
          break
        case 'equipment':
          this.nameFilePdf = 'DanhSachDungCuThietBiPhucVuKiemDinh.pdf'
          break
        case 'document':
          this.nameFilePdf = 'DanhSachTaiLieuPhucVuKiemDinh.pdf'
          break
        case 'inspector':
          this.nameFilePdf = 'DanhSachPhanCongKiemDinhVien.pdf'
          break
        case 'accreditation':
          this.nameFilePdf = 'GiayChungNhanKiemDinh.pdf'
          break
        case 'renewExtend':
          this.nameFilePdf = 'DonDeNghiGiaHan,CapLaiKiemDinh.pdf'
          break
        default:
          break
      }
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.nameFilePdf) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
#testingId {
  .button-testing {
    min-width: 100%;
    margin-bottom: 1rem;
  }
  .buttom-add {
    // height: 42px;
    button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .button-action {
    min-width: 100%;
  }
  .button-delete {
    height: 40px;
    min-width: 100%;
  }
  .title-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  .border-status {
    border-radius: 4px;
  }
}
</style>
