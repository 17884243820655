<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm đối tượng kiểm định"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateInspector"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        label-for="user"
      >
        <label for="user">Kiểm định viên <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customWorker"
        >
          <v-select
            v-model="dataInput.inspectorId"
            :class="errors.length > 0 ? 'is-invalid':null"
            :reduce="Value => Value.id"
            label="name"
            :options="dataCombobox || []"
            :placeholder="'Lựa chọn kiểm định viên'"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              {{ option.name }}
            </template>
            <template #selected-option-container="{ option }">
              <div class="vs__selected">
                {{ option.name }}
              </div>
            </template>
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
      >
        <label for="user">Phạm vi kiểm định</label>
        <b-form-input v-model="dataInput.limit" />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      customWorker: {
        required: 'Kiểm định viên là bắt buộc',
      },
      dataCombobox: [],
      dataInput: {
        inspectorId: '',
        limit: '',
      },
    }
  },
  created() {
    this.fetchComboboxInspector()
  },
  methods: {
    // Danh sách Kiểm định viên
    async fetchComboboxInspector() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_INSPECTOR_NOT_REGISTER)
      this.dataCombobox = data?.data
    },

    // Tạo đôi tượng kiểm định
    handleCreateInspector(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateInspector', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        inspectorId: '',
        limit: '',
      }
    },
  },
}
</script>
