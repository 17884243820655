<template>
  <div id="licenseId">
    <my-tabs
      :listTabs="listTabs"
      defaultActive="actions"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div>
      <actions
        v-if="isRendered('actions')"
        :fetchDataBusiness="fetchDataBusiness"
        :dataInfomationBusiness="dataInfomationBusiness"
        class="tab-pane page-container"
        :class="{'active':activeTab==='actions'}"
        @handleDeleteLicense="handleDeleteLicense"
      />
      <test-object
        v-if="isRendered('inspect-object')"
        :fetchDataBusiness="fetchDataBusiness"
        :licenseAccredidationId="licenseAccredidationId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='inspect-object'}"
      />
      <add-file
        v-if="isRendered('files')"
        :licenseAccredidationId="licenseAccredidationId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='files'}"
      />
      <equipment
        v-if="isRendered('instruments-testing-equipment')"
        :licenseAccredidationId="licenseAccredidationId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='instruments-testing-equipment'}"
      />
      <document
        v-if="isRendered('documentation-inspection')"
        :licenseAccredidationId="licenseAccredidationId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='documentation-inspection'}"
      />
      <inspector
        v-if="isRendered('inspector')"
        :licenseAccredidationId="licenseAccredidationId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='inspector'}"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import MyTabs from '@/components/tabs/MyTabs.vue'
import Actions from './components/Actions.vue'
import TestObject from './components/TestObject.vue'
import AddFile from './components/AddFile.vue'
import Equipment from './components/Equipment.vue'
import Document from './components/Document.vue'
import Inspector from './components/Inspector.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    MyTabs,
    Actions,
    TestObject,
    AddFile,
    Equipment,
    Document,
    Inspector,
  },
  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: 'actions',
          title: 'Hành động',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'inspect-object',
          title: 'Đối tượng kiểm định',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'files',
          title: 'File đính kèm',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'instruments-testing-equipment',
          title: 'Dụng cụ, thiết bị kiểm định',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'documentation-inspection',
          title: 'Tài liệu phục vụ kiểm định',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'inspector',
          title: 'Kiểm định viên',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
      ],
      dataInfomationBusiness: {},
      licenseAccredidationId: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  async created() {
    await this.fetchDataBusiness()

    if (this.activeTab === null) {
      this.activeTab = 'actions'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    // Lấy thông tin cấp phép kiểm định theo doanh nghiệp
    async fetchDataBusiness() {
      const data = await axiosApiInstance.get(ConstantsApi.INFORMATIN_BUSINESS)
      if (data?.data?.statusLicenseAccrediation !== 'NonLiscening') {
        this.licenseAccredidationId = data?.data?.id
        this.dataInfomationBusiness = data?.data
        this.listTabs[1].isDisabled = false
        this.listTabs[2].isDisabled = false
        this.listTabs[3].isDisabled = false
        this.listTabs[4].isDisabled = false
        this.listTabs[5].isDisabled = false
      }
      // eslint-disable-next-line no-constant-condition
      if ((['Licensing', 'Renew', 'Extend', 'RejectLicense', 'Revoke'].includes(data?.data?.statusLicenseAccrediation))
            && data.status !== 204) {
        this.licenseAccredidationId = data?.data?.id
        this.dataInfomationBusiness = data?.data
        this.listTabs[1].isDisabled = true
        this.listTabs[2].isDisabled = true
        this.listTabs[3].isDisabled = true
        this.listTabs[4].isDisabled = true
        this.listTabs[5].isDisabled = true
      }
      if (data.status === 204) {
        this.listTabs[1].isDisabled = true
        this.listTabs[2].isDisabled = true
        this.listTabs[3].isDisabled = true
        this.listTabs[4].isDisabled = true
        this.listTabs[5].isDisabled = true
        this.dataInfomationBusiness = {
          statusLicenseAccrediation: 'NonLiscening',
          statusLicenseAccrediationString: 'Chưa cấp phép',
        }
      }
    },
    async handleDeleteLicense() {
      const deleteId = []
      deleteId.push(this.licenseAccredidationId)
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.DELETE_SUSGESS_INSPECTION, deleteId)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa giấy phép thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataBusiness()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa giấy phép không thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
  },
}
</script>
