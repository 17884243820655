<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm dụng cụ, thiết bị kiểm định"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateEquipment"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        label-for="user"
      >
        <label for="user">Tên dụng cụ <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customInput"
        >
          <b-form-input
            id="InputHelp"
            v-model="dataInput.name"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Thông số kỹ thuật</label>
        <b-form-input v-model="dataInput.information" />
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Tình trạng hiệu chuẩn/kiểm định</label>
        <b-form-input v-model="dataInput.status" />
      </b-form-group>

      <b-form-group
        class="mt-1"
      >
        <label for="user">Thời hạn hiệu chuẩn</label>
        <date-time-picker
          v-model="dataInput.dateExpired"
          :disabledInput="true"
        />
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Mã số chế tạo thiết bị</label>
        <b-form-input v-model="dataInput.code" />
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Tình trạng thiết bị</label>
        <b-form-input v-model="dataInput.statusEquipment" />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      customInput: {
        required: 'Tên dụng cụ là bắt buộc',
      },
      dataInput: {
        name: '',
        information: '',
        status: '',
        code: '',
        statusEquipment: '',
        dateExpired: '',
      },
    }
  },
  methods: {
    // Tạo đôi tượng kiểm định
    handleCreateEquipment(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateEquipment', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        name: '',
        information: '',
        status: '',
        code: '',
        statusEquipment: '',
        dateExpired: '',
      }
    },
  },
}
</script>
