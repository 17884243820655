<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm tài liệu phục vụ kiểm định"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateDocument"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <div class="modal-accept">
        <b-form-group>
          <label>Tên file</label>
          <div class="d-flex">
            <b-form-input
              v-model="nameFile"
              :disabled="true"
            >
              {{ nameFile }}
            </b-form-input>
            <input
              id="file"
              ref="file-second"
              type="file"
              class="input-file"
              @change="importFileExcel"
            />
            <b-button
              variant="outline-primary"
              class="btn-button-file ml-1"
              @click="inputFile"
            >
              <div
                class="d-flex"
              >
                <feather-icon
                  icon="UploadIcon"
                  for="file"
                  class="input_label text-primary"
                />
                <label
                  class="text-primary ml-50"
                >Tải lên</label>
              </div>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <b-form-group class="mt-1">
        <label for="user">Ký hiệu văn bản</label>
        <b-form-input v-model="dataInput.Code" />
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Tình trạng hiệu lực</label>
        <b-form-input v-model="dataInput.Status" />
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Cơ quan ban hành</label>
        <b-form-input v-model="dataInput.Unit" />
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Ghi chú</label>
        <b-form-input v-model="dataInput.Note" />
      </b-form-group>

    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      customInput: {
        required: 'Tên file là bắt buộc',
      },
      nameFile: null,
      modelFormData: {},
      dataInput: {
        Name: '',
        Code: '',
        Status: '',
        Unit: '',
        Note: '',
      },
    }
  },
  methods: {
    // Tạo đôi tượng kiểm định
    handleCreateDocument(bvModalEvt) {
      const formData = new FormData()
      formData.append('IsSecure', this.modelFormData.isSecure)
      formData.append('formFile', this.modelFormData.files)
      formData.append('Name', this.nameFile)
      formData.append('Code', this.dataInput.Code)
      formData.append('Status', this.dataInput.Status)
      formData.append('Unit', this.dataInput.Unit)
      formData.append('Note', this.dataInput.Note)
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateDocument', formData)
        }
      })
    },
    resetState() {
      this.nameFile = null
      this.dataInput = {
        Name: '',
        Code: '',
        Status: '',
        Unit: '',
        Note: '',
      }
    },
    inputFile() {
      this.$refs['file-second'].click()
    },
    importFileExcel(e) {
      this.nameFile = this.$refs['file-second'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .btn-button-file {
    width: 30%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>
