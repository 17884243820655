export default {
  INFORMATIN_BUSINESS: 'LicenseAccreditation/license-accreditation-by-bussiness',
  ADD_SUSGESS_INSPECTION: 'LicenseAccreditation/licenseAccreditations',
  DELETE_SUSGESS_INSPECTION: 'LicenseAccreditation/delete-licenseAccreditations',
  LIST_TEST_OBJECT: 'LicenseAccreditation/list-target-accreditation',
  CREATE_TEST_OBJECT: 'LicenseAccreditation/add-target-accreditation',
  DELETE_TEST_OBJECT: 'LicenseAccreditation/delete-target-accreditation',
  COMBOBOX_EQUIPMENT_NOT_REGISTER: 'LicenseAccreditation/get-combobox-index-equipment-not-register',
  LIST_EQUIPMENT_LICENSE: 'LicenseAccreditation/list-license-accreditation-equipment',
  CREATE_EQUIPMENT_LICENSE: 'LicenseAccreditation/add-license-accreditation-equipment',
  DELETE_EQUIPMENT_LICENSE: 'LicenseAccreditation/delete-license-accreditation-equipment',
  LIST_DOCUMENT_LICENSE: 'LicenseAccreditation/list-license-accreditation-document',
  CREATE_DOCUMENT_LICENSE: 'LicenseAccreditation/add-license-accreditation-document',
  DELETE_DOCUMENT_LICENSE: 'LicenseAccreditation/delete-license-accreditation-document',
  LIST_INSPECTOR: 'LicenseAccreditation/list-license-accreditation-inspector',
  CREATE_INSPECTOR: 'LicenseAccreditation/add-license-accreditation-inspector',
  DELETE_INSPECTOR: 'LicenseAccreditation/delete-license-accreditation-inspector',
  COMBOBOX_INSPECTOR_NOT_REGISTER: 'LicenseAccreditation/get-combobox-inspector-not-register',
  LIST_FILE_LICENSE: 'LicenseAccreditation/list-license-accreditation-file',
  CREATE_FILE_LICENSE: 'LicenseAccreditation/add-license-accreditation-file',
  DELETE_FILE_LICENSE: 'LicenseAccreditation/delete-license-accreditation-file',
  RENEW_LICENSE: 'LicenseAccreditation/require-renew-license-accreditation',
  EXTEND_LICENSE: 'LicenseAccreditation/require-extend-license-accreditation',
}
